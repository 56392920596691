import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import IAccount from "../../../interfaces/IAccount";
import { request } from "../../../utils/axios-utils";

const SyncAccountOption: React.FC<{
    accountNumber: string;
    accountIsSync: Function;
}> = (props) => {
    const [isSync, setIsSync] = useState<boolean>(false);
    const [account, setAccount] = useState<IAccount>(null);

    useEffect(() => {
        request({
            addToast,
            url: `/api/accounts/number-and-user`,
            method: "post",
            data: { accountNumber: props.accountNumber },
        })
            .then(({ data }) => {
                if (data.account) {
                    setAccount(data.account);
                    setIsSync(data.account.is_synced);
                    props.accountIsSync(data.account.is_synced);
                }
            })
            .catch();
    }, []);

    const { addToast } = useToasts();

    // Synchronisation du compte
    const syncAccountWithId = () => {
        request({
            addToast,
            url: `/api/accounts/synchronise/${account._id}`,
            method: "post",
            data: {},
        })
            .then(({ data }) => {
                addToast("Le compte est maintenant synchronisé !", {
                    appearance: "success",
                });
                setIsSync(true);
                props.accountIsSync(true);
            })
            .catch();
    };

    // Desynchronisation du compte
    const desyncAccount = () => {
        request({
            addToast,
            url: `/api/accounts/desynchronise/${account._id}`,
            method: "post",
            data: {},
        })
            .then(({ data }) => {
                addToast("Le compte n'est plus synchronisé !", {
                    appearance: "success",
                });
                setIsSync(false);
                props.accountIsSync(false);
            })
            .catch();
    };

    return (
        <>
            <div className="sync_account">
                <div className="">
                    {isSync && (
                        <div className="d-flex flex-column align-items-center">
                            <div className="d-flex justify-content-center">
                                {account && account?.sync_at}
                                <button
                                    className={
                                        "cursor-pointer bg-transparent hover:bg-red-600 text-green-600 font-semibold hover:text-white py-2 px-4 border border-green-600 hover:border-transparent rounded"
                                    }
                                    style={{
                                        marginLeft: 10,
                                    }}
                                    disabled={account === null}
                                    onClick={() => {
                                        syncAccountWithId();
                                    }}
                                >
                                    Actif
                                </button>
                                <span
                                    className={`${
                                        account ? "sync_tooltip_text" : "hide"
                                    }`}
                                    id="top"
                                >
                                    Cela supprimera les répétitions.
                                </span>
                                <span
                                    className={`${
                                        !account ? "sync_tooltip_text" : "hide"
                                    }`}
                                    id="top"
                                >
                                    Ce compte n'est associé à aucun protégé.
                                </span>
                            </div>
                        </div>
                    )}
                    {!isSync && (
                        <div className="d-flex flex-column align-items-center">
                            <div className="d-flex justify-content-center">
                                <button
                                    className={
                                        "cursor-pointer bg-transparent hover:bg-green-600 text-red-600 font-semibold hover:text-white py-2 px-4 border border-red-600 hover:border-transparent rounded"
                                    }
                                    style={{
                                        marginLeft: 10,
                                    }}
                                    onClick={() => {
                                        desyncAccount();
                                    }}
                                >
                                    Inactif
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
export default SyncAccountOption;
