//Represent the side bar items
export const SideNavItems = [
    {
        _id: 'dashboard',
        name: 'Accueil',
        icon: 'home',
        href: '/dashboard',
        roles: ['super-admin', 'user', 'INVOICE_ACCOUNT', 'MANAGER', 'REP_MJPM', 'ACCOUNTANT', 'FAMILLE', 'CRG_VERIF'],
    },
    {
        _id: 'listMajeurs',
        name: 'Protégés',
        icon: 'people',
        href: '/list_majeurs',
        roles: ['super-admin', 'user', 'REP_MJPM', 'ACCOUNTANT', 'MANAGER', 'FAMILLE', 'CRG_VERIF'],
    },
    {
        _id: 'listContacts',
        name: "Carnet d'adresses",
        icon: 'contacts',
        href: '/contacts',
        roles: ['super-admin', 'user', 'REP_MJPM', 'ACCOUNTANT', 'MANAGER'],
    },
    {
        _id: 'Documents',
        name: 'Générateur docs',
        icon: 'insert_drive_file',
        href: '/documents',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER'],
    },
    {
        _id: 'listAlarms',
        name: 'Alertes',
        icon: 'notifications',
        href: '/alertes',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', 'CRG_VERIF', 'FAMILLE'],
    },
    {
        _id: 'tribunaux',
        name: 'Tribunaux',
        icon: 'gavel',
        href: '/tribunaux',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER'],
    },
    {
        _id: 'listBanks',
        name: 'Banques',
        icon: 'business',
        href: '/banques',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', "ACCOUNTANT"],
    },
    {
        _id: 'statistiques',
        name: 'Statistiques',
        icon: 'trending_up',
        href: '/statistiques',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', 'tester'],
    },
    {
        _id: 'listUsers',
        name: 'Utilisateurs',
        icon: 'group',
        href: '/utilisateurs',
        roles: ['super-admin', 'INVOICE_ACCOUNT', 'MANAGER', 'tester'],
    },
    {
        _id: 'listTemplatesMail',
        name: 'Courriers type',
        icon: 'mail',
        href: '/list_templates_mail',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', "ACCOUNTANT"],
    },
    {
        _id: 'listLetters',
        name: 'Courriers',
        icon: 'markunread_mailbox',
        href: '/lettres',
        roles: ['super-admin', 'user', 'REP_MJPM', 'MANAGER', "ACCOUNTANT"],
    },
    {
        _id: 'settings',
        name: 'Paramètres',
        icon: 'settings',
        href: '/settings',
        roles: ['super-admin', 'user', 'INVOICE_ACCOUNT', 'MANAGER', 'ACCOUNTANT'],
    }
];
