import { request } from "../utils/axios-utils";

export const updateSetting = (addToast: any, type: any, fileMongo: any) => {
    return request({
        addToast,
        url: `/api/user/settings/update`,
        method: "post",
        data: { type: type, file: fileMongo },
    });
};

export const getSettings = (addToast: any) => {
    return request({ addToast, url: `/api/user/settings` });
};

export const setUserScanner = (addToast: any, payload: any) => {
    return request({
        addToast,
        url: `/api/user/settings/scanner`,
        method: "post",
        data: { scanner: payload },
    });
};
