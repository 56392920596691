import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import moment from "moment";
import PizZip from "pizzip";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { modules, momentUpdateLocale } from "../../../../constants/Utils";
import IMail from "../../../../interfaces/IMail";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import IUser from "../../../../interfaces/IUser";
import { request } from "../../../../utils/axios-utils";
import ArrowBackActions from "../../../layout/ArrowBackAction";
import Loader from "../../../layout/Loader";
const SingleCourrier: React.FC<{
    majeurId?: string;
    majeur?: IProtectedAdult;
}> = (props) => {
    const [mail, setMail] = useState<IMail>(null);

    const { courrierId }: { courrierId: string } = useParams();

    const { addToast } = useToasts();
    const history = useHistory();
    const [majeur, setMajeur] = useState<IProtectedAdult>(null);
    const [notDocx, setNotDocx] = useState(null);
    const [values, setValues] = useState(null);
    const [docData, setDocData] = useState(null);
    const [user, setUser] = useState<IUser>(null);
    const [loading, setLoading] = useState<boolean>(false);

    momentUpdateLocale();

    useEffect(() => {
        if (!user) {
            request({ addToast, url: `/api/super_admin` })
                .then(({ data }) => {
                    setUser(data);
                })
                .catch();
        }

        request({
            addToast,
            url: `/api/majeurMails/download/${courrierId}`,
            method: "post",
            data: {},
        })
            .then((data) => {
                setNotDocx(data.data);
                setDocData(data.data.docBase64);
                setValues(data.data.values);
            })
            .catch();
    }, []);

    const uploadMail = () => {
        let filename = mail.label
            .split("|")[0]
            .trim()
            .replace(/undefined/g, "")
            .replace(/\s/g, "_")
            .replace(/[<>:"\/\\|?*\x00-\x1F]/g, "");
        if (docData) {
            let content = atob(docData);
            let zip = new PizZip(content);
            let doc = new Docxtemplater(zip, {
                linebreaks: true,
            });

            doc.setData(values);

            try {
                doc.render();
            } catch (error) {
                console.error(error);
            }

            let output = doc.getZip().generate({
                type: "blob",
                mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });

            saveAs(output, filename + ".docx");
        }
        if (!notDocx) {
            return;
        }
        if (!docData) {
            let urlWithFileName = notDocx.split("finalFileName=");
            urlWithFileName =
                urlWithFileName[0] + "finalFileName=" + filename + ".docx";
            document.location.href = urlWithFileName;
        }
    };

    const uploadMailPdf = () => {
        setLoading(true);
        let filename = mail.label
            .split("|")[0]
            .trim()
            .replace(/undefined/g, "")
            .replace(/\s/g, "_")
            .replace(/[<>:"\/\\|?*\x00-\x1F]/g, "");
        if (docData) {
            let content = atob(docData);
            let zip = new PizZip(content);
            let doc = new Docxtemplater(zip, {
                linebreaks: true,
            });

            doc.setData(values);

            try {
                doc.render();
            } catch (error) {
                console.error(error);
            }

            let output = doc.getZip().generate({
                type: "blob",
                mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });

            let formData = new FormData();
            formData.append("file", output, filename + ".docx");

            request({
                url: `/api/maileva/convert/document`,
                method: "POST",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                responseType: "blob",
            })
                .then((response) => {
                    saveAs(response.data, filename + ".pdf");
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    addToast("Erreur lors du téléchargement en PDF.", {
                        appearance: "error",
                    });
                });
        }
        if (!notDocx) {
            return;
        }
        if (!docData) {
            request({
                url: `/api/maileva/convert/document`,
                method: "POST",
                data: {
                    url: notDocx,
                    filename: filename,
                },
                responseType: "blob",
            })
                .then((response) => {
                    saveAs(response.data, filename + ".pdf");
                    setLoading(false);
                })
                .catch((error) => {
                    addToast("Erreur lors de l'envoi du courrier.", {
                        appearance: "error",
                    });
                    setLoading(false);
                });
        }
    };

    const deleteMail = () => {
        request({
            addToast,
            url: `/api/majeurMails/${courrierId}/delete`,
            method: "post",
            data: {},
        })
            .then(() => {
                addToast("Le courrier a bien été supprimé.", {
                    appearance: "success",
                });
                history.push(`/single_majeur/${majeur._id}/mails`);
            })
            .catch();
    };

    const createLetter = () => {
        if (!majeur.addresses || majeur.addresses.length === 0) {
            addToast("Votre majeur n'a pas d'adresse", { appearance: "error" });
            return;
        }
        setLoading(true);
        request({
            addToast,
            url: `/api/maileva/create-letter`,
            method: "POST",
            data: {
                letter: {
                    name: `Courrier ${majeur.first_name} ${
                        majeur.last_name
                    } du ${moment().format("L")}`,
                    custom_id: majeur._id + "_" + moment().format("L"),
                    custom_data: majeur._id + "_" + moment().format("L"),
                    color_printing: false,
                    duplex_printing: false,
                    optional_address_sheet: false,
                    notification_email: user.emails[0].address,
                    print_sender_address: false,
                    sender_address_line_1: "",
                    sender_address_line_2:
                        user.profile.infos.civility +
                        " " +
                        user.profile.infos.first_name +
                        " " +
                        user.profile.infos.last_name,
                    sender_address_line_3: "",
                    sender_address_line_4: user.profile.infos.address,
                    sender_address_line_5: "",
                    sender_address_line_6:
                        user.profile.infos.zip_code + " " + user.profile.infos.city,
                    sender_country_code: "FR",
                    envelope_windows_type: "SIMPLE",
                    postage_type: "ECONOMIC",
                    treat_undelivered_mail: false,
                    notification_treat_undelivered_mail: [],
                },
                majeur_id: majeur._id,
            },
        }).then((r) => {
            request({
                url: `/api/maileva/add-recipient/${r.data.id}`,
                method: "POST",
                data: {
                    country_code: "FR",
                    address_line_1: "",
                    address_line_2: majeur.first_name + " " + majeur.last_name,
                    address_line_3: "",
                    address_line_4: majeur.addresses[0].address,
                    address_line_5: "",
                    address_line_6:
                        majeur.addresses[0].zip_code +
                        " " +
                        majeur.addresses[0].city,
                },
            })
                .then(() => {
                    addDocumentLetter(r.data.id);
                })
                .catch(() => {
                    setLoading(false);
                    addToast("Erreur lors de l'ajout du destinataire", {
                        appearance: "error",
                    });
                })
                .catch();
        });
    };

    const addDocumentLetter = (id: string) => {
        if (docData) {
            let content = atob(docData);
            let zip = new PizZip(content);
            let doc = new Docxtemplater(zip, {
                linebreaks: true,
            });

            doc.setData(values);

            try {
                doc.render();
            } catch (error) {
                console.error(error);
            }

            let output = doc.getZip().generate({
                type: "blob",
                mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });

            let formData = new FormData();
            formData.append(
                "file",
                output,
                mail.label
                    .split("|")[0]
                    .trim()
                    .replace(/undefined/g, "")
                    .replace(/\s/g, "_")
                    .replace(/[<>:"\/\\|?*\x00-\x1F]/g, "") + ".docx"
            );

            request({
                url: `/api/maileva/add-document-from-mail/${id}`,
                method: "POST",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((response) => {
                    history.push(`/lettres/${id}/modifier`);
                    addToast("Le courrier a bien été créé.", {
                        appearance: "success",
                    });
                })
                .catch((error) => {
                    addToast("Erreur lors de l'envoi du courrier.", {
                        appearance: "error",
                    });
                });
        }
        if (!notDocx) {
            return;
        }
        if (!docData) {
            request({
                url: `/api/maileva/add-document-from-mail/${id}`,
                method: "POST",
                data: {
                    url: notDocx,
                    filename: mail.label
                        .split("|")[0]
                        .trim()
                        .replace(/undefined/g, "")
                        .replace(/\s/g, "_")
                        .replace(/[<>:"\/\\|?*\x00-\x1F]/g, ""),
                },
                responseType: "blob",
            })
                .then((response) => {
                    history.push(`/lettres/${id}/modifier`);
                    addToast("Le courrier a bien été créé.", {
                        appearance: "success",
                    });
                })
                .catch((error) => {
                    addToast("Erreur lors de l'envoi du courrier.", {
                        appearance: "error",
                    });
                });
        }
    };

    const actionsArrowBack = [
        {
            title: "add_letter",
            icon: "mail",
            action: createLetter,
        },
        {
            title: "download_pdf",
            icon: "picture_as_pdf",
            action: uploadMailPdf,
        },
        {
            title: "download",
            src: "/imgs/icons/docx.svg",
            action: uploadMail,
        },
        {
            title: "edit",
            icon: "edit",
            action: () =>
                history.push(
                    "/single_mail/" + majeur._id + "/edit/" + courrierId
                ),
        },
        {
            title: "delete",
            icon: "delete_forever",
            question: "Voulez vous supprimer ce courrier ?",
            action: deleteMail,
        },
    ];

    useEffect(() => {
        if (!mail) {
            request({ addToast, url: `/api/majeurMails/${courrierId}` })
                .then(({ data }) => {
                    setMail(data);
                    request({
                        addToast,
                        url: `/api/majeur/${data.majeur_id}`,
                    }).then(({ data }) => {
                        setMajeur(data);
                    });
                })
                .catch();
        }
    }, []);

    const titleCourrier = mail
        ? mail.type === "Courrier"
            ? "Courrier"
            : "Requête"
        : null;

    let title;
    if (majeur && window.screen.width > 600) {
        title = `${majeur.first_name} ${majeur.last_name} | `;
    } else {
        title = "";
    }

    return (
        <>
            {loading && <Loader />}
            {!loading && (
                <div>
                    <ArrowBackActions
                        title={title + titleCourrier}
                        actions={actionsArrowBack}
                        back={() => window.history.back()}
                        classNameTitle={""}
                    />
                    {mail && (
                        <div className="page_content layout-center-center column">
                            {mail.label && (
                                <div
                                    className="page_content layout-center-center"
                                    style={{ paddingTop: 0 }}
                                >
                                    {mail.label.replace(/undefined/g, "")}
                                </div>
                            )}
                            {mail && !mail.body && (
                                <div className="mail_container">
                                    Votre courrier est un fichier word appuyez
                                    sur la flèche en haut à droite pour le
                                    télécharger !
                                </div>
                            )}
                            {mail && mail.body && (
                                <div className="mail_container">
                                    <ReactQuill
                                        readOnly
                                        value={mail.body}
                                        modules={{ ...modules, toolbar: false }}
                                    />
                                    <div className="layout-end-center row edited text_zone">
                                        édité le :{" "}
                                        {moment(mail.created_at).format("L")}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
export default SingleCourrier;
