import React, { useEffect, useState } from "react";
import Switch from "react-bootstrap-switch";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import { request } from "../../../utils/axios-utils";
import InputComponent from "../../layout/forms/InputComponent";
import Modal from "../../layout/modals/Modal";

const Ebics: React.FC = () => {
    const [ebicsBanks, setEbicsBanks] = useState<any>(null);

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const { addToast } = useToasts();

    const [bankType, setBankType] = useState<any>("");
    const [selectedBank, setSelectedBank] = useState<any>("");
    const [isCertified, setIsCertified] = useState<any>(true);
    const [partnerID, setPartnerID] = useState<any>("");
    const [hostID, setHostID] = useState<any>("");
    const [userID, setUserID] = useState<any>("");
    const [bankUrl, setBankUrl] = useState<any>("");

    const handleModalValidate = () => {
        request({
            url: "api/ebics/create",
            method: "post",
            data: {
                partnerID: partnerID,
                hostID: hostID,
                userID: userID,
                bankUrl: bankUrl,
                bankType: bankType,
                isCertified: isCertified,
            },
        })
            .then(() =>
                addToast("Connexion établie", {
                    appearance: "success",
                })
            )
            .catch((error) => {
                console.log(error);
                addToast("Une erreur est survenue", {
                    appearance: "error",
                });
            });
    };

    useEffect(() => {
        if (!ebicsBanks) {
            request({ addToast, url: `/api/ebics/accounts` })
                .then(({ data }) => {
                    setEbicsBanks(data);
                })
                .catch();
        }
    }, []);

    return (
        <>
            <div className="ebics">
                {(!ebicsBanks || ebicsBanks.length === 0) && (
                    <div className="layout-center-center row div-no-items">
                        {
                            "Il n'y a pas encore de banques synchronisées avec EBICS"
                        }
                    </div>
                )}
                {ebicsBanks &&
                    ebicsBanks.length > 0 &&
                    ebicsBanks.map((e: any) => {
                        return (
                            <div key={e.ebics_user_id} className="card_bank">
                                <div className="name">{e.bank_name}</div>
                                <div className="account">
                                    <div>
                                        {e.status.text}
                                        <span className="import_date">
                                            {" "}
                                            (Le certificat expire le :{" "}
                                            {e.expires.date})
                                        </span>
                                        {e.state === "HIA" && (
                                            <button
                                                type="button"
                                                style={{ alignSelf: "center" }}
                                                className="valid-button"
                                                onClick={() =>
                                                    console.log("TODO")
                                                }
                                            >
                                                Télécharger les certificats de
                                                la banque
                                            </button>
                                        )}
                                        {e.state === "HPB" && !e.ready && (
                                            <a
                                                target="_blank"
                                                href={
                                                    process.env
                                                        .REACT_APP_BASE_URL +
                                                    "api/ebics/letter?userID=" +
                                                    e.ebics_user_id
                                                }
                                            >
                                                Télécharger la lettre INI
                                            </a>
                                        )}
                                        {e.state === "HPB" &&
                                            e.ready &&
                                            "Connexion prête"}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                <div className="row layout-center-center">
                    <button
                        onClick={() => setModalIsOpen(true)}
                        className="m-2 cursor-pointer bg-transparent hover:bg-green-600 text-green-600 font-semibold hover:text-white py-2 px-4 border border-green-600 hover:border-transparent rounded"
                    >
                        Ajouter une Banque avec EBICS
                    </button>
                    <Modal
                        show={modalIsOpen}
                        onValidate={handleModalValidate}
                        onClose={() => {
                            setModalIsOpen(false);
                        }}
                        message="Ajouter une connexion ebics 2.5"
                    >
                        <div>
                            <div style={{ width: "100%" }}>
                                <InputComponent
                                    id="userID"
                                    className="my-input flex-1 margin-top"
                                    label="UserId"
                                    required
                                    type="text"
                                    value={userID}
                                    onChange={(e) => setUserID(e.target.value)}
                                    maxLength={50}
                                />
                                <InputComponent
                                    id="hostID"
                                    className="my-input flex-1 margin-top"
                                    label="hostID"
                                    required
                                    type="text"
                                    value={hostID}
                                    onChange={(e) => setHostID(e.target.value)}
                                    maxLength={50}
                                />
                                <InputComponent
                                    id="partnerID"
                                    className="my-input flex-1 margin-top"
                                    label="partnerID"
                                    required
                                    type="text"
                                    value={partnerID}
                                    onChange={(e) =>
                                        setPartnerID(e.target.value)
                                    }
                                    maxLength={50}
                                />
                                <InputComponent
                                    id="bankUrl"
                                    className="my-input flex-1 margin-top"
                                    label="Url de la banque"
                                    required
                                    type="text"
                                    value={bankUrl}
                                    onChange={(e) => setBankUrl(e.target.value)}
                                    maxLength={75}
                                />
                                <Select
                                    id="bankType"
                                    className="margin-top margin-bottom"
                                    value={selectedBank}
                                    required
                                    onChange={(e) => {
                                        setBankType(e.value),
                                            setSelectedBank(e);
                                    }}
                                    options={[
                                        { value: "", label: "Autre" },
                                        {
                                            value: "CE",
                                            label: "Caisse d'Epargne",
                                        },
                                        {
                                            value: "CA",
                                            label: "Crédit Agricole",
                                        },
                                        {
                                            value: "CM",
                                            label: "Crédit Mutuel CIC",
                                        },
                                        {
                                            value: "BNP",
                                            label: "BNP",
                                        },
                                        {
                                            value: "COOP",
                                            label: "COOP",
                                        },
                                    ]}
                                />
                                <div>
                                    <label style={{ marginRight: "10px" }}>
                                        Banque française ?
                                    </label>
                                    <Switch
                                        bsSize="small"
                                        onText="Oui"
                                        offText="Non"
                                        value={isCertified}
                                        onChange={() =>
                                            setIsCertified(!isCertified)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default Ebics;
