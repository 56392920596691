import moment from "moment";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
    formatTelephone,
    momentUpdateLocale,
} from "../../../../constants/Utils";
import IContract from "../../../../interfaces/IContract";
import IProtectedAdult from "../../../../interfaces/IProtectedAdult";
import { request } from "../../../../utils/axios-utils";
import ArrowBackActions from "../../../layout/ArrowBackAction";
import InputPassword from "../../../layout/InputPassword";
import ItemTwoLines from "../../../layout/ItemTwoLines";
import ItemTwoLinesLink from "../../../layout/ItemTwoLinesLink";
import Loader from "../../../layout/Loader";
import IFile from "../Cloud/IFile";

const SingleContract: React.FC<{}> = (props) => {
    const [contract, setContract] = useState<IContract>(null);
    const [majeur, setMajeur] = useState<IProtectedAdult>();

    const [password, setPassword] = useState("");
    const [clipboard, setClipboard] = useState("");
    const [copied, setCopied] = useState<boolean>(false);
    const [fileList, setFileList] = useState<Array<IFile>>(null);

    const { contractId }: { contractId?: string } = useParams();
    const history = useHistory();
    const { addToast } = useToasts();

    const getLink = (websiteUrl: string) => {
        return websiteUrl;
    };

    momentUpdateLocale();

    const removeContract = () => {
        request({
            addToast,
            url: `/api/contracts/${contractId}/delete`,
            method: "post",
            data: {},
        })
            .then(() => {
                window.history.back();
                addToast("La répétition a bien été supprimée.", {
                    appearance: "success",
                });
            })
            .catch();
    };

    const decryptPassword = () => {
        request({
            addToast,
            url: `/api/contracts/password/${contractId}`,
        })
            .then(({ data }) => {
                setPassword(data);
                setClipboard(data);
            })
            .catch();
    };

    const download = (key: string) => {
        request({
            addToast,
            url: `/api/files/downloadWithKey`,
            method: "POST",
            data: { key: key },
            responseType: "blob",
        })
            .then((response) => {
                const blob = response.data;
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", key.split("/").pop());
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((data) => {
                const { message } = data;
                addToast(message, { appearance: "error" });
            });
    };

    const changeVisibilityAccounts = () => {};

    useEffect(() => {
        if (!contract) {
            request({ addToast, url: `/api/contracts/${contractId}` })
                .then(({ data }) => {
                    decryptPassword();
                    setContract(data);
                    if (data.files) {
                        request({
                            url: "/api/files/list/from-ids",
                            method: "POST",
                            data: { filesIds: data.files },
                        })
                            .then(({ data }) => {
                                setFileList(data);
                            })
                            .catch();
                    }
                    request({
                        addToast,
                        url: `/api/majeur/${data.majeur_id}`,
                    })
                        .then(({ data }) => {
                            setMajeur(data);
                        })
                        .catch();
                })
                .catch();
        }
    }, []);

    const actionsArrowBack =
        majeur && !majeur.archived
            ? [
                  {
                      title: "edit",
                      icon: "edit",
                      action: () =>
                          history.push(
                              `/form_contract/${majeur._id}/edit/${contract._id}`
                          ),
                  },
                  {
                      title: "delete",
                      icon: "delete_forever",
                      question: "Voulez vous supprimer ce droit contract ?",
                      action: removeContract,
                  },
              ]
            : [];

    const title =
        majeur && window.screen.width > 600
            ? `${majeur.first_name} ${majeur.last_name} | `
            : "";

    return (
        <div className="single_contract">
            <ArrowBackActions
                title={`${title} Droit contract`}
                actions={actionsArrowBack}
                back={() => window.history.back()}
                classNameTitle={""}
            />
            <div className="page_content">
                {!contract && <Loader />}

                {contract && (
                    <>
                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="Nature"
                                content={contract.nature || "Non renseigné."}
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Numéro"
                                content={contract.numero || "Non renseigné."}
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Organisme"
                                content={
                                    contract.contact.organisme
                                        ? contract.contact.organisme
                                        : contract.organisme
                                        ? contract.organisme
                                        : "Non renseigné."
                                }
                                flex="1"
                            />
                        </div>
                        <div className="row layout-xs-column">
                            <ItemTwoLines
                                title="Date de début"
                                content={
                                    contract.start_date &&
                                    contract.start_date !== ""
                                        ? moment(
                                              contract.start_date,
                                              moment.ISO_8601
                                          ).format("L")
                                        : "Non renseignée."
                                }
                                flex="1"
                            />
                            <div className="flex005 hide-xs" />
                            <ItemTwoLines
                                title="Date de fin"
                                content={
                                    contract.end_date &&
                                    contract.end_date !== ""
                                        ? moment(
                                              contract.end_date,
                                              moment.ISO_8601
                                          ).format("L")
                                        : "Non renseignée."
                                }
                                flex="1"
                            />
                        </div>
                        {contract.organisme &&
                            contract.contact &&
                            !contract.contact._id && (
                                <div className="column flex-1">
                                    <ItemTwoLines
                                        title="Adresse"
                                        content={
                                            contract.address ||
                                            contract.zip_code ||
                                            contract.city
                                                ? `${contract.address} ${contract.zip_code} ${contract.city}`
                                                : "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                    <div className="row layout-xs-column">
                                        <ItemTwoLines
                                            title="Telephone"
                                            content={
                                                contract.phone ||
                                                "Non renseigné."
                                            }
                                            flex="1"
                                        />
                                        <div className="flex005 hide-xs" />
                                        <ItemTwoLines
                                            title="Email"
                                            content={
                                                contract.email ||
                                                "Non renseigné."
                                            }
                                            flex="1"
                                        />
                                    </div>
                                    <ItemTwoLines
                                        title="Contact"
                                        content={
                                            contract.contact.first_name ||
                                            contract.contact.last_name
                                                ? `${contract.contact.civility} ${contract.contact.first_name} ${contract.contact.last_name}`
                                                : "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                </div>
                            )}
                        {contract.contact && contract.contact._id && (
                            <div
                                className="informations_generales_container"
                                style={{ padding: "0 5px" }}
                            >
                                {contract.contact.addresses &&
                                    contract.contact.addresses.length > 0 && (
                                        <div className="block_container">
                                            <h5>Adresses :</h5>
                                            <div className="layout-center-start column">
                                                {contract.contact.addresses.map(
                                                    (a) => (
                                                        <div
                                                            key={a.label}
                                                            className="items"
                                                        >
                                                            <h6>{a.label}.</h6>
                                                            <p className="address_zone">
                                                                {a.address}
                                                            </p>
                                                            <p>
                                                                {a.zip_code}{" "}
                                                                {a.city}
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {contract.contact.phones &&
                                    contract.contact.phones.length > 0 && (
                                        <div className="block_container">
                                            <h5>Téléphones :</h5>
                                            <div className="layout-center-start column">
                                                {contract.contact.phones.map(
                                                    (p) => (
                                                        <div
                                                            key={p.label}
                                                            className="items"
                                                        >
                                                            <h6>{p.label}.</h6>
                                                            <p>
                                                                {formatTelephone(
                                                                    p.phone
                                                                )}
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {contract.contact.emails &&
                                    contract.contact.emails.length > 0 && (
                                        <div className="block_container">
                                            <h5>Emails :</h5>
                                            <div className="layout-center-start column">
                                                {contract.contact.emails.map(
                                                    (e) => (
                                                        <div
                                                            key={e.label}
                                                            className="items"
                                                        >
                                                            <h6>{e.label}.</h6>
                                                            <p>{e.email}</p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                            </div>
                        )}
                        <div className="row layout-xs-column password-line">
                            <ItemTwoLinesLink
                                title="Adresse site internet"
                                content={
                                    contract.websiteUrl || "Non renseigné."
                                }
                                target="_blank"
                                link={getLink(contract.websiteUrl)}
                            />
                            <div className="flex005 hide-xs" />
                            {contract.login && (
                                <ItemTwoLines
                                    title="Login"
                                    content={contract.login || "Non renseigné."}
                                />
                            )}
                            <div className="flex005 hide-xs" />
                            <div className="password_field flex-1">
                                <InputPassword
                                    id="password_contract"
                                    className="flex-1"
                                    label="Mot de passe"
                                    decryptFunction={decryptPassword}
                                    value={password || ""}
                                    disabled
                                    onChange={() => {}}
                                    disablePasswordGenerator
                                />
                                <CopyToClipboard
                                    text={clipboard}
                                    onCopy={() => {
                                        setCopied(true);
                                    }}
                                >
                                    <i
                                        style={{
                                            marginLeft: 5,
                                            marginTop: 20,
                                        }}
                                        className="material-icons pointer"
                                        onClick={changeVisibilityAccounts}
                                    >
                                        content_copy
                                    </i>
                                </CopyToClipboard>
                                {copied && clipboard !== "" && (
                                    <span>Copié !</span>
                                )}
                                {copied && clipboard === "" && (
                                    <span>
                                        Mot de passe de l'ancienne version,
                                        merci de le ressaisir
                                    </span>
                                )}
                            </div>
                        </div>
                        <ItemTwoLines
                            title="Alerte"
                            content={contract.alarm ? "Oui" : "Non"}
                            flex="1"
                        />
                        <div className="my-input-container">
                            <label>Notes :</label>
                            {contract && contract.notes && (
                                <div className="notes_zone">
                                    {contract.notes}
                                </div>
                            )}
                            {!contract.notes && <div>Non renseignées.</div>}
                        </div>
                        <div className="my-input-container">
                            <label>Document :</label>
                            {fileList &&
                                fileList.length > 0 &&
                                fileList.map((f) => (
                                    <div className="notes_zone layout-start-center">
                                        {f.name}{" "}
                                        <i
                                            onClick={() => download(f.key)}
                                            className="material-icons pointer"
                                        >
                                            cloud_download
                                        </i>
                                    </div>
                                ))}
                            {!fileList && <div>Non renseignées.</div>}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default SingleContract;
