import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { SideNavItems } from "../../constants/SideBarItems";
import ISideBarItem from "../../interfaces/ISideBarItem";
import { request } from "../../utils/axios-utils";

const SideNav: React.FC<{
    close?: Function;
}> = (props) => {
    const [role, setRole] = useState<string>(null);
    const items = SideNavItems.filter(
        (x: ISideBarItem) => x.roles.indexOf(role) > -1
    );
    const { addToast } = useToasts();

    useEffect(() => {
        if (!role) {
            request({ addToast, url: `/api/user` }).then(({ data }) => {
                setRole(data.roles[0]);
            }).catch();
        }
    }, [role]);

    //Return _id from the actual route
    const testRoute = () => {
        var actualRoute = "";
        for (let item of items) {
            if (window.location.href.indexOf(item.href) > -1) {
                actualRoute = item._id;
            }
        }

        // Specific cases for listMajeurs
        if (
            window.location.href.indexOf("single_account") > -1 ||
            window.location.href.indexOf("single_majeur") > -1
        ) {
            actualRoute = "listMajeurs";
        }

        return actualRoute;
    };

    return (
        <nav id="sidenav" className="Sidenav column no_print NORMAL_SIZE">
            <div className="header layout-start-center row">
                <div className="small-logo"></div>
                <h1>Menu</h1>
            </div>
            <div className="menuItemContainer">
                {items.map((item: ISideBarItem) => (
                    <div
                        key={item._id}
                        className={`sidenav_item ${
                            item._id === testRoute() ? "selected" : ""
                        }`}
                    >
                        {props.close && (
                            <Link
                                onClick={() => props.close()}
                                to={item.href}
                                className="ItemMenu layout-start-center row"
                            >
                                <i className="material-icons">{item.icon}</i>
                                <span className="menuTitle">{item.name}</span>
                            </Link>
                        )}

                        {!props.close && (
                            <Link
                                to={item.href}
                                className="ItemMenu layout-start-center row"
                            >
                                <i className="material-icons">{item.icon}</i>
                                <span className="menuTitle">{item.name}</span>
                            </Link>
                        )}

                        <div className="divider" />
                    </div>
                ))}
            </div>
        </nav>
    );
};
export default SideNav;
