import React, { useState } from "react";
import { CATS, MVT_WITH_AMOUNT } from "../../../constants/Utils";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";

const FormAddLabelWithSubCategoriesAndAmount: React.FC<{
    getSubCategoriesFormLinkedLabel: Function;
    listSubCategoriesForSelect: Array<any>;
    submit: Function;
    selectedSubCategory: any;
    accountsOptions: any;
    updateInfos: Function;
}> = (props) => {
    const [textInput, setTextInput] = useState<string>("");
    const [amount, setAmount] = useState<string>("");
    const [selectedAccount, setSelectedAccount] = useState<any>();
    const [selectedAccountsTarget, setSelectedAccountsTarget] = useState<any>(
        props.accountsOptions[0].value
    );
    const [isLabelFromSubCat, setIsLabelFromSubCat] = useState<boolean>(false);
    const [selectedMouvment, setSelectedMouvment] = useState<string>(
        MVT_WITH_AMOUNT[0].value
    );
    const [categories, setCategories] = useState<Array<any>>(
        CATS.filter((c) => c.mvt === 0)
    );
    const [selectedCategory, setSelectedCategory] = useState<string>(
        categories[0].value
    );
    const [selectedSubCategory, setSelectedSubCategory] = useState<string>(
        props.selectedSubCategory.value
    );
    const [selectedSubCategoryName, setSelectedSubCategoryName] =
        useState<string>(props.selectedSubCategory.label);

    const onChangeMouvement = (option: any, account?: any, mvt?: number) => {
        if (parseInt(option.value) < 2) {
            let newCategories = CATS.filter(
                (c) => c.mvt === parseInt(option.value)
            );
            setCategories(newCategories);
            setSelectedCategory(newCategories[0].value);
            props.getSubCategoriesFormLinkedLabel(newCategories[0].value);
        } else {
            props.getSubCategoriesFormLinkedLabel(null, null, account, mvt);
        }
    };

    const onChangeCategory = (option: any) => {
        setSelectedCategory(option.value);
        props.getSubCategoriesFormLinkedLabel(option.value);
    };

    const onChangeSubCategory = (option: any) => {
        setSelectedSubCategory(option.value);
        props.getSubCategoriesFormLinkedLabel(selectedCategory, option.label);
    };

    return (
        <>
            <form className="column form_add_categories">
                <Select
                    id="mouvment"
                    label="Mouvement "
                    required
                    options={MVT_WITH_AMOUNT}
                    value={selectedMouvment}
                    onChange={(e) => {
                        setIsLabelFromSubCat(false);
                        setSelectedMouvment(e.target.selectedOptions[0].value);
                        if (parseInt(e.target.selectedOptions[0].value) < 2) {
                            setSelectedSubCategory(
                                props.selectedSubCategory.value
                            );
                            setSelectedAccount(null);
                            props.updateInfos(null, selectedMouvment);
                            onChangeMouvement(e.target.selectedOptions[0]);
                        } else {
                            setSelectedAccountsTarget(
                                props.accountsOptions[0].value
                            );
                            setSelectedAccount(props.accountsOptions[0].data);
                            props.updateInfos(
                                props.accountsOptions[0].data,
                                e.target.selectedOptions[0].value
                            );
                            onChangeMouvement(
                                e.target.selectedOptions[0],
                                props.accountsOptions[0].data,
                                e.target.selectedOptions[0].value
                            );
                        }
                    }}
                />
                {(selectedMouvment == "0" || selectedMouvment == "1") && (
                    <div>
                        <Select
                            id="category"
                            label="Categorie"
                            required
                            options={categories}
                            value={selectedCategory}
                            onChange={(e) => {
                                setIsLabelFromSubCat(false);
                                onChangeCategory(e.target.selectedOptions[0]);
                                setSelectedSubCategory(
                                    props.selectedSubCategory.value
                                );
                            }}
                        />
                        <Select
                            id="subCategory"
                            label="Sous Catégorie"
                            required
                            options={props.listSubCategoriesForSelect}
                            value={selectedSubCategory}
                            onChange={(e) => {
                                setIsLabelFromSubCat(true);
                                onChangeSubCategory(
                                    e.target.selectedOptions[0]
                                );
                                setSelectedSubCategoryName(
                                    e.target.selectedOptions[0].label
                                );
                            }}
                        />
                    </div>
                )}
                {(selectedMouvment == "2" || selectedMouvment == "3") && (
                    <Select
                        id="cpt_cpt"
                        label="Compte cible"
                        required
                        options={props.accountsOptions}
                        value={selectedAccountsTarget}
                        onChange={(e) => {
                            setSelectedAccountsTarget(
                                e.target.selectedOptions[0].value
                            );
                            setSelectedAccount(
                                props.accountsOptions[
                                    e.target.selectedOptions[0].value - 1
                                ].data
                            );
                            props.updateInfos(
                                props.accountsOptions[
                                    e.target.selectedOptions[0].value - 1
                                ].data,
                                selectedMouvment
                            );
                            props.getSubCategoriesFormLinkedLabel(
                                null,
                                null,
                                props.accountsOptions[
                                    e.target.selectedOptions[0].value - 1
                                ].data,
                                selectedMouvment
                            );
                        }}
                    />
                )}
                <InputComponent
                    id="amount"
                    className="my-input"
                    label="Montant"
                    required
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    maxLength={50}
                />
                <InputComponent
                    id="label"
                    className="my-input"
                    label="Label à asssocier"
                    required
                    value={textInput}
                    onChange={(e) => setTextInput(e.target.value)}
                    maxLength={50}
                />
                <button
                    type="button"
                    onClick={() =>
                        props.submit(
                            selectedMouvment,
                            selectedCategory,
                            isLabelFromSubCat
                                ? selectedSubCategoryName
                                : props.selectedSubCategory.label,
                            textInput,
                            selectedAccount,
                            amount
                        )
                    }
                    className="valid-button"
                >
                    Ajouter
                </button>
            </form>
        </>
    );
};
export default FormAddLabelWithSubCategoriesAndAmount;
