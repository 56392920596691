import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { uploadDoc } from "../../../_api/S3";
import { getSettings, updateSetting } from "../../../_api/User";
import { request } from "../../../utils/axios-utils";
import Divider from "../../layout/Divider";
import FullBarTitle from "../../layout/FullBarTitle";
import FormAddTags from "../../layout/forms/FormAddTags";
import ListTags from "../../layout/forms/ListTags";

const WarningText = (props: any) => {
    const { document, text, defaut } = props;
    return (
        <>
            {document && document.url ? (
                <div className="success_message_container">
                    <p>Un document avec votre {text} est présent !</p>
                </div>
            ) : (
                <div
                    className={
                        defaut
                            ? "default_message_container"
                            : "error_message_container"
                    }
                >
                    <p>Le document avec votre {text} est absent !</p>
                </div>
            )}
        </>
    );
};
const MailSettings: React.FC = () => {
    const { addToast } = useToasts();

    const [templateMail, setTemplateMail] = useState<any>(null);
    const [noticeInfos, setNoticeInfos] = useState<any>(null);
    const [signature, setSignature] = useState<any>(null);

    const [S3FolderUser, setS3FolderUser] = useState<string>(null);
    const [listOfTags, setListOfTags] = useState<any>([]);

    useEffect(() => {
        if (!templateMail && !noticeInfos) {
            getSettings(addToast)
                .then(({ data }) => {
                    setTemplateMail(
                        data.template_mail ? data.template_mail : null
                    );
                    setSignature(data.signature ? data.signature : null);
                    setNoticeInfos(
                        data.notice_infos ? data.notice_infos : null
                    );
                    setS3FolderUser(data.S3_folder_user);
                    actualiseTags();
                })
                .catch();
        }
    }, [templateMail, noticeInfos]);

    const createS3File = (e: any, _type: string) => {
        e.preventDefault();

        const fileS3 = e.target.files[0];

        const key =
            S3FolderUser +
            "/" +
            (Math.random() + 1).toString(36).substring(7) +
            "_" +
            fileS3.name.replace(/[^a-zA-Z0-9.]/gi, "_");

        const formData = new FormData();
        formData.append("file", fileS3);
        formData.append("key", key);
        formData.append("is_visible", "true");
        console.log(fileS3);
        uploadDoc(formData)
            .then((response) => {
                updateSetting(addToast, _type, {
                    url:
                        process.env.AWS_ENDPOINT +
                        process.env.AWS_BUCKET +
                        "/" +
                        key,
                    key: key,
                    type: fileS3.type,
                    size: fileS3.size,
                    name: fileS3.name,
                    mimetype: fileS3.type,
                })
                    .then((data) => {
                        addToast(`Le document a bien été mis en ligne.`, {
                            appearance: "success",
                        });
                        setTemplateMail(null);
                    })
                    .catch();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const create = (tag: any) => {
        request({
            addToast,
            url: "/api/tags/store",
            method: "post",
            data: { tag: tag },
        })
            .then((data) => {
                addToast(`Le tag a bien été créé`, { appearance: "success" });
                actualiseTags();
            })
            .catch();
    };

    const actualiseTags = () => {
        request({ addToast, url: `/api/users/tags` })
            .then(({ data }) => {
                setListOfTags(data);
            })
            .catch();
    };

    const remove = (tag: any) => {
        request({
            addToast,
            url: `/api/tags/${tag._id}/destroy`,
            method: "post",
            data: {},
        })
            .then((data) => {
                // The data has been saved.
                addToast(`Le tag a bien été supprimé`, {
                    appearance: "success",
                });
                actualiseTags();
            })
            .catch();
    };

    return (
        <>
            <div className="mail_settings">
                <FullBarTitle text="Votre modèle de courrier" />
                <WarningText
                    document={templateMail}
                    text="En-tête et Pied de page"
                />
                <div className="page_content">
                    <div className="row layout-space-between-center upload_mail_container">
                        <span>
                            Télécharger votre en tête et pied de page ( .docx )
                            :
                        </span>
                        <i
                            onClick={() =>
                                document.getElementById("fileInput").click()
                            }
                            className="material-icons pointer"
                        >
                            cloud_upload
                        </i>
                    </div>
                </div>
                <input
                    id="fileInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) => createS3File(event, "mail")}
                />
                <Divider />
                <FullBarTitle text="Votre modèle de la notice infos et récépissé" />
                <WarningText
                    document={noticeInfos}
                    text="notice infos et récépissé"
                />
                <div className="page_content">
                    <div className="row layout-space-between-center upload_mail_container">
                        <span>
                            Télécharger votre modèle de notice infos et
                            récépissé ( .docx ) :
                        </span>
                        <i
                            onClick={() =>
                                document
                                    .getElementById("fileInputNoticeInfos")
                                    .click()
                            }
                            className="material-icons pointer"
                        >
                            cloud_upload
                        </i>
                    </div>
                </div>
                <input
                    id="fileInputNoticeInfos"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) => createS3File(event, "notice_infos")}
                />
                <Divider />
            </div>
            <FullBarTitle text="Votre signature de courrier" />
            <WarningText document={signature} text="signature" />
            <div className="page_content">
                <div className="row layout-space-between-center upload_mail_container">
                    <span>Télécharger votre signature :</span>
                    <i
                        onClick={() =>
                            document
                                .getElementById("fileInputSignature")
                                .click()
                        }
                        className="material-icons pointer"
                    >
                        cloud_upload
                    </i>
                </div>
            </div>
            <input
                id="fileInputSignature"
                type="file"
                style={{ display: "none" }}
                onChange={(event) => createS3File(event, "signature")}
            />
            <div className="optional_settings">
                <div className="subBar_actions layout-start-center row">
                    <span className="subBar_actions_title">
                        Suivi du majeur : Editer vos tags
                    </span>
                </div>
                <div className="page_content">
                    <div className="row">
                        <FormAddTags submit={(l: any) => create(l)} />
                        <ListTags
                            listOfTags={listOfTags}
                            remove={(l: any) => remove(l)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default MailSettings;
